import React from "react"
import styles from "./productDetail.module.scss"
import { Link } from "gatsby"
import Menubar from "../components/menubar/menubar"
import ProjectTopImage from "../img/proje_975.jpg"
import { useStaticQuery, graphql } from "gatsby"
import Carousel from "react-bootstrap/Carousel"
import Footer from "../components/footer/footer"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronRight } from "@fortawesome/free-solid-svg-icons"
import { faInstagram, faFacebookF } from "@fortawesome/free-brands-svg-icons"
import PD02 from "../img/PD02.jpg"
import PD03 from "../img/PD03.jpg"

const ProductDetail = () => {
  const data = useStaticQuery(graphql`
    query {
      one: file(relativePath: { eq: "nykonutu1.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 873, maxHeight: 468) {
            src
          }
        }
      }
    }
  `)
  return (
    <div className={styles.productDetailContainer}>
      <div className={styles.leftE}></div>
      <div className={styles.centerSite}>
        <Menubar />
        <div className={styles.imageTopProductDetailContainer}>
          <img
            src={ProjectTopImage}
            alt="projects"
            className={styles.imageTopProductDetail}
          />
          <div className={styles.verticalLineWhiteProductDetail}>
            <div className={styles.socialMediaIcons}>
              <a
                href="https://www.facebook.com/atiaahsap"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon
                  icon={faFacebookF}
                  size="1x"
                  className={styles.ffIcon}
                />
              </a>
              <br />
              <a
                href="https://www.instagram.com/atiaahsap/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon
                  icon={faInstagram}
                  size="1x"
                  className={styles.igIcon}
                />
              </a>
            </div>
          </div>
          <span className={styles.topSpanProductDetail}>
            MY <br />
            KONUTU
            <br />
            <Link to="/projeler" className={styles.backLinkPD}>
              TÜM PROJELER <FontAwesomeIcon icon={faChevronRight} />
            </Link>
          </span>
        </div>
        <div className={styles.pdContainer}>
          <div className={styles.pDmiddleContainer}>
            <Carousel indicators={false} fade={true} id="pdetailCar">
              <Carousel.Item>
                <img
                  src={data.one.childImageSharp.fluid.src}
                  alt="first of item detail"
                />
              </Carousel.Item>
              <Carousel.Item>
                <img src={PD02} alt="second of item detail" />
              </Carousel.Item>
              <Carousel.Item>
                <img src={PD03} alt="third of item detail" />
              </Carousel.Item>
            </Carousel>
            <div className={styles.PDMiddleTextBox}>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Quam
                recusandae repellendus soluta, minima consequatur, ut assumenda,
                omnis neque officiis corporis facilis totam? Saepe, blanditiis
                reprehenderit voluptatibus rem dolorum doloribus nihil ullam
                nobis.
              </p>
            </div>
          </div>
        </div>
        <Footer />
      </div>
      <div className={styles.rightE}></div>
    </div>
  )
}

export default ProductDetail
